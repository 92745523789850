body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.div_login {
  position: absolute;
  z-index: 2;
}

.div_bg_login {


  position: absolute;
  z-index: 1;
  overflow: visible;
  opacity: 0.3;
  cursor: pointer;
  pointer-events: auto;
  background: url(../public/bg_login.png) 0px 0px / 1638px 1171px;
  border-style: solid;
  border-color: rgb(0, 0, 0);
  border-width: 0px;
  border-radius: 0px;
  width: 100%;
  height: 100vh;
  transform: translateZ(1e+12px) translate(410px, -190px) translate(819px, 0px) rotate(0deg) translate(-819px, 0px);
}


.div_mark_login {

  padding-top: 13px;
  color: rgba(255, 255, 255, 1);
  padding-right: 20px;
  margin: 0px;

  box-sizing: border-box;

  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  text-align: right;

  background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 35, 226, 1) 63%, rgba(63, 133, 189, 1) 100%);
  width: 100%;
  height: 100vh;
  position: absolute;
}

.div_right_login_1 {

  position: absolute;
  z-index: 2;
  overflow: visible;
  opacity: 1;
  cursor: pointer;
  pointer-events: auto;
  background: url(https://batdongsan.vr360.one/du-an/binh-chanh-360-v2/data/skin/helpcore/p2.png) 0px 0px / 410px 472px;
  border-style: solid;
  border-color: rgb(0, 0, 0);
  border-width: 0px;
  border-radius: 0px;
  width: 410px;
  height: 472px;
  transform: translateZ(1e+12px) translate(1229px, -157px) translate(0px, 0px) rotate(0deg) translate(0px, 0px);
}

.div_right_login_2 {

  position: absolute;
  z-index: 2;
  overflow: visible;
  opacity: 1;
  cursor: pointer;
  pointer-events: auto;
  background: url(https://batdongsan.vr360.one/du-an/binh-chanh-360-v2/data/skin/helpcore/p1.png) 0px 0px / 922px 888px;
  border-style: solid;
  border-color: rgb(0, 0, 0);
  border-width: 0px;
  border-radius: 0px;
  width: 922px;
  height: 888px;
  transform: translateZ(1e+12px) translate(1485px, -246px) translate(0px, 0px) rotate(0deg) translate(0px, 0px);
}

.div_right_login_3 {

  position: absolute;
  z-index: 2;
  overflow: visible;
  opacity: 1;
  cursor: pointer;
  pointer-events: auto;
  background: url(https://batdongsan.vr360.one/du-an/binh-chanh-360-v2/data/skin/helpcore/p3.png) 0px 0px / 410px 472px;
  border-style: solid;
  border-color: rgb(0, 0, 0);
  border-width: 0px;
  border-radius: 0px;
  width: 410px;
  height: 472px;
  transform: translateZ(1e+12px) translate(1474px, 80px) translate(0px, 0px) rotate(0deg) translate(0px, 0px);

}

.div_right_login_4 {

  position: absolute;
  z-index: 2;
  overflow: visible;
  opacity: 1;
  cursor: pointer;
  pointer-events: auto;
  background: url(https://batdongsan.vr360.one/du-an/binh-chanh-360-v2/data/skin/helpcore/p6.png) 0px 0px / 369px 424px;
  border-style: solid;
  border-color: rgb(0, 0, 0);
  border-width: 0px;
  border-radius: 0px;
  width: 369px;
  height: 424px;
  transform: translateZ(1e+12px) translate(1003px, 420px) translate(0px, 0px) rotate(0deg) translate(0px, 0px);
}

.div_right_login_5 {

  position: absolute;
  z-index: 2;
  overflow: visible;
  opacity: 1;
  cursor: pointer;
  pointer-events: auto;
  background: url(https://batdongsan.vr360.one/du-an/binh-chanh-360-v2/data/skin/helpcore/p5.png) 0px 0px / 369px 373px;
  border-style: solid;
  border-color: rgb(0, 0, 0);
  border-width: 0px;
  border-radius: 0px;
  width: 369px;
  height: 373px;
  transform: translateZ(1e+12px) translate(1249px, 327px) translate(0px, 0px) rotate(0deg) translate(0px, 0px);
}

.div_right_login_6 {
  position: absolute;
  z-index: 2;
  overflow: visible;
  opacity: 1;
  cursor: pointer;
  pointer-events: auto;
  background: url(https://batdongsan.vr360.one/du-an/binh-chanh-360-v2/data/skin/helpcore/p4.png) 0px 0px / 492px 494px;
  border-style: solid;
  border-color: rgb(0, 0, 0);
  border-width: 0px;
  border-radius: 0px;
  width: 492px;
  height: 494px;
  transform: translateZ(1e+12px) translate(1597px, 417px) translate(0px, 0px) rotate(0deg) translate(0px, 0px);

}


/* styles.css */
.image-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.label {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 3px;
}